<template>
  <div
    ref="text-block"
    :class="[
      {
        '--align-center': align === 'center',
        '--align-right': align === 'right',
        '--invert-colors': invertColors,
        '--checked-authentication': hasCheckedAuthentication
      },
      colorClass,
      'text-block container'
    ]"
    :style="{ padding: blockPadding }"
  >
    <div
      :class="[
        { '--collapsed': isCollapsed },
        'text-block__collapse-wrapper p-relative'
      ]"
    >
      <div class="text-block__collapse-content">
        <template>
          <div
            ref="collapse-content"
            data-role="text-block-content"
            :class="['text-block__content', { '--collapsed': isCollapsed }]"
          >
            <v-html
              ref="html-content"
              data-role="html"
              :html="blockContent"
              no-padding
            />
          </div>

          <v-button
            v-if="isCollapsable && !collapsed"
            class="pl-0 ml-0 text-block__collapse-button"
            small
            secondary
            ghost
            @click="() => (collapsed = true)"
          >
            <h6 class="m-0">
              {{ $t("components.textBlock.viewLess") }}
            </h6>
          </v-button>
        </template>
      </div>

      <div
        v-if="isCollapsable && collapsed"
        class="text-block__collapse-overflow p-overlay justify-content-start"
        @click="() => (collapsed = false)"
      >
        <v-button
          class="pl-0 ml-0 text-block__collapse-button"
          small
          secondary
          ghost
        >
          <h6 class="m-0">
            {{ $t("components.textBlock.viewMore") }}
          </h6>
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import { getTextContent } from "@shared/page-attributes"
import { isBuilderContext } from "@/lib/utils"

import EventBus, { EVENTS } from "@/lib/event-bus"
import { decodeBlockAction } from "@/lib/stream-utils"
import enumValidator from "@/lib/validators/enum-validator"

import scssVariables from "@/assets/styles/variables.module.scss"

import vHtml from "@/components/vHtml"
import { TEXT_COLORS } from "@/lib/stream-utils"
import { withRatio, toPadding } from "@shared/block-ratio"

const remToNumber = unit => parseFloat(unit.replace("rem", ""))
const defaultYRatio = ratio => Math.max(ratio > 50 ? ratio - 50 : ratio, 0)

const TEXT_BLOCK_COLLAPSE_HEIGHT = 130

const BASE_SIZES = {
  h1: remToNumber(scssVariables["font-size--h1"] || "2.25rem"),
  h2: remToNumber(scssVariables["font-size--h2"] || "1.5rem"),
  h3: remToNumber(scssVariables["font-size--h3"] || "1.25rem"),
  h6: remToNumber(scssVariables["font-size--h6"] || "0.85rem"),
  p: remToNumber(scssVariables["font-size--base"] || "1.125rem"),
  ol: remToNumber(scssVariables["font-size--base"] || "1.125rem"),
  ul: remToNumber(scssVariables["font-size--base"] || "1.125rem")
}

export default {
  components: {
    vHtml
  },

  props: {
    align: {
      type: String,
      default: "left",
      validator: enumValidator(["left", "center", "right"])
    },
    content: { type: String, default: "" },
    pageAttributeSlug: { type: String, default: null },
    // TODO: Remove
    dynamicContent: { type: Object, default: () => ({}) },
    collapsable: { type: Boolean, default: false },
    sizeRatio: { type: Number, default: 100 },
    spaceXRatio: {
      type: Number,
      default: 100
    },
    spaceTopRatio: {
      type: Number,
      default: 100
    },
    spaceBottomRatio: {
      type: Number,
      default: 100
    },
    tag: {
      type: String,
      default: "p",
      validator: enumValidator(["p", "h1", "h2", "h3", "h6", "ul", "ol"])
    },
    color: {
      type: String,
      default: null,
      validator: enumValidator([null, ...Object.keys(TEXT_COLORS)])
    },
    pageId: {
      type: [Number, String],
      default: null
    },
    autoconvertUnits: {
      type: Boolean,
      default: false
    },
    invertColors: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      blockContent: "",
      isCollapsable: this.collapsable,
      collapsed: true
    }
  },

  computed: {
    ...mapGetters("pages", ["getPageAttributes", "currentPage"]),
    ...mapGetters("auth", [
      "subscriberAttributes",
      "isAuthenticated",
      "hasCheckedAuthentication"
    ]),

    isCollapsed() {
      return this.isCollapsable && this.collapsed
    },

    lineHeight() {
      const padding = this.tag.startsWith("h") ? 0.3 : 0.6
      return withRatio(BASE_SIZES[this.tag] + padding, this.sizeRatio, {
        unit: "rem"
      })
    },

    colorClass() {
      return this.color ? `--color-${this.color}` : null
    },

    blockPadding() {
      return toPadding(
        defaultYRatio(this.spaceTopRatio),
        this.spaceXRatio,
        defaultYRatio(this.spaceBottomRatio)
      )
    },

    pageAttributes() {
      return this.getPageAttributes(this.pageId) || {}
    }
  },

  mounted() {
    this.setupContent()

    this.$nextTick(() => {
      this.updateCollapsable()
    })

    if (this.autoconvertUnits) {
      import("@/lib/autoconvert-units").then(m => {
        this.$refs["html-content"].$el.innerHTML = m.default(
          this.$refs["html-content"].$el.innerHTML
        )
      })
    }
  },

  watch: {
    content() {
      this.updateCollapsable()
    },

    collapsable(collapsable) {
      this.isCollapsable = collapsable
      this.updateCollapsable()
    },

    isAuthenticated() {
      // We're regenerating content here,
      // as we need to update the the member attribute after user log in/out
      this.setupContent()
    }
  },

  methods: {
    setupContent() {
      this.blockContent = getTextContent(
        {
          data: {
            page_attribute_slug: this.pageAttributeSlug,
            content: this.content
          }
        },
        this.pageAttributes,
        this.subscriberAttributes
      )

      this.setupLinks()
    },

    updateCollapsable() {
      if (this.collapsable) {
        const content = this.$refs["collapse-content"]
        const { height } = content.getBoundingClientRect()

        if (height > TEXT_BLOCK_COLLAPSE_HEIGHT) {
          this.isCollapsable = true
        } else {
          this.isCollapsable = false
          this.collapsed = false
        }
      }
    },

    setupLinks() {
      if (isBuilderContext()) return

      this.$nextTick(() => {
        const element = this.$refs["html-content"].$el
        if (!element) return

        element.querySelectorAll("a[action]").forEach(e => {
          e.addEventListener("click", () => this.handleLinkClick(e))
        })
      })
    },

    handleLinkClick(element) {
      EventBus.$emit(
        EVENTS.TRIGGER_BLOCK_ACTION,
        decodeBlockAction(element.getAttribute("action"))
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

$text-block--collapse-height: 130px;

.text-block {
  &.--align-center {
    text-align: center;
  }

  &.--align-right {
    text-align: right;
  }

  &.--color-white {
    color: $color-fixed--white;
  }

  &.--color-light_grey {
    color: $color-fixed--grey-20;
  }

  &.--color-grey {
    color: $color-fixed--grey-70;
  }

  &.--color-black {
    color: $color-fixed--black;
  }

  &.--color-primary {
    color: var(--primary-brand-color-hex);
  }

  &.--color-secondary {
    color: var(--secondary-brand-color-hex);
  }

  &.--color-dark {
    color: var(--dark-brand-color-hex);
  }

  &.--color-light {
    color: var(--light-brand-color-hex);
  }
}

.text-block__collapse-wrapper {
  overflow: hidden;
  max-height: initial;

  &.--collapsed {
    max-height: $text-block--collapse-height;
  }

  .text-block__collapse-overflow {
    animation: fade-in-up 0.2s ease;
    position: absolute;
    top: 0;
    left: 0;
    height: $text-block--collapse-height;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
}

.text-block.--invert-colors .text-block__collapse-button {
  h6 {
    color: $color-ui--grey-70;
  }
}
</style>

<style lang="scss">
@import "@/assets/styles/variables";

.text-block {
  &:not(.--checked-authentication) {
    [data-role="html"] [data-type="data-field"] {
      // Prevent from showing placeholder (e.g @first_name)
      display: none;
    }
  }
}

.text-block__content {
  &.--collapsed {
    mask-image: linear-gradient(
      to bottom,
      rgb(255, 255, 255) 0,
      rgba(0, 0, 0, 0) 110px
    );
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--heading-font-stack);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: inherit;
    margin: 0 0 0.5rem;

    &:empty:before {
      content: "";
      display: inline-block;
    }
  }

  > *:first-child,
  > .v-html > *:first-child {
    margin-top: 0;
  }

  > *:last-child,
  > .v-html > *:last-child {
    margin-bottom: 0;
  }

  &.--text-light {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $color-ui--white;
    }
  }

  // Convert to SASS Function?
  h1 {
    &.size-75 {
      font-size: 1.6875rem;
      line-height: 1.9125rem;
    }

    &.size-100 {
      font-size: $font-size--h1;
      line-height: $font-size--h1 + 0.3;
    }

    &.size-125 {
      font-size: 2.8125rem;
      line-height: 3.1875rem;
    }

    &.size-150 {
      font-size: 3.375rem;
      line-height: 3.825rem;
    }
  }

  h2 {
    &.size-75 {
      font-size: 1.125rem;
      line-height: 1.35rem;
    }

    &.size-100 {
      font-size: $font-size--h2;
      line-height: $font-size--h2 + 0.3;
    }

    &.size-125 {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }

    &.size-150 {
      font-size: 2.25rem;
      line-height: 2.7rem;
    }
  }

  h3 {
    &.size-75 {
      font-size: 0.9375rem;
      line-height: 1.1625rem;
    }

    &.size-100 {
      font-size: $font-size--h3;
      line-height: $font-size--h3 + 0.3;
    }

    &.size-125 {
      font-size: 1.5625rem;
      line-height: 1.9375rem;
    }

    &.size-150 {
      font-size: 1.875rem;
      line-height: 2.325rem;
    }
  }

  h6 {
    &.size-75 {
      font-size: 0.6375rem;
      line-height: 0.8624rem;
    }

    &.size-100 {
      font-size: $font-size--h6;
      line-height: $font-size--h6 + 0.3;
    }

    &.size-125 {
      font-size: 1.0625rem;
      line-height: 1.4375rem;
    }

    &.size-150 {
      font-size: 1.275rem;
      line-height: 1.7245rem;
    }
  }

  p {
    margin-top: 0;

    &.size-75 {
      font-size: 0.84375rem;
      line-height: 1.29375rem;
    }

    &.size-100 {
      font-size: $font-size--base;
      line-height: $font-size--base + 0.5;
    }

    &.size-125 {
      font-size: 1.40625rem;
      line-height: 1.725rem;
    }

    &.size-150 {
      font-size: 1.6875rem;
      line-height: 2.5875rem;
    }
  }

  ol,
  ul {
    padding-left: $size--8;

    li {
      padding-left: $size--1;
    }

    &.size-75 {
      p,
      li {
        font-size: 0.84375rem;
        line-height: 1.29375rem;
      }
    }

    &.size-100 {
      p,
      li {
        font-size: $font-size--base;
        line-height: $font-size--base + 0.5;
      }
    }

    &.size-125 {
      p,
      li {
        font-size: 1.40625rem;
        line-height: 1.725rem;
      }
    }

    &.size-150 {
      p,
      li {
        font-size: 1.6875rem;
        line-height: 2.5875rem;
      }
    }
  }

  img {
    max-width: 100%;
  }
}
</style>
