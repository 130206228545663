import Vue from "vue"
import VueMeta from "vue-meta"
import VueGtag from "vue-gtag"
import VuePortal from "@linusborg/vue-simple-portal"
import router from "@/router"
import store from "@/store"
import i18n from "@/store/i18n"

import "@/plugins"

import "@/registerServiceWorker"
import "@/assets/styles/application.scss"

import pwaTheme from "@/lib/pwa-theme"
import { initEmbeddedGoogleFont } from "@/lib/fonts"
import { addExternalLinks } from "@/lib/add-external-links"
import { addGlobalCSS, addGlobalCode } from "@/lib/add-global-code"
import setupRewardful from "@/lib/rewardful"
import { VARIABLES } from "@/lib/globals"
import { setupStripe } from "@/lib/mixins/use-stripe"
import { get, LS_KEYS } from "@/lib/local-storage"
import { defaultAppGlobalState, fetchGlobalState } from "@/lib/global-state"

import App from "@/App.vue"
import { AppGlobalState } from "@shared/types"

Vue.config.productionTip = false

Vue.use(VueMeta, { keyName: "head" })

Vue.use(VuePortal)

if (["production", "staging"].includes(process.env.VUE_APP_ENV || "")) {
  Vue.use(VueGtag, { bootstrap: false }, router)
}

Vue.config.errorHandler = err => {
  if (process.env.NODE_ENV !== "production") {
    console.error(err)
  }
}

const initializeApp = (state: AppGlobalState = defaultAppGlobalState) => {
  const storageItem = get(LS_KEYS.GLOBAL_STATE) as AppGlobalState | undefined

  state = { ...state, ...storageItem }

  if (state.app?.google_tag_manager_id) {
    const VueGtm = require("@gtm-support/vue2-gtm")

    Vue.use(VueGtm.default, {
      id: state.app?.google_tag_manager_id,
      enabled: true,
      vueRouter: router
    })
  }

  store.commit("setGlobalState", state)
  window[VARIABLES.GLOBAL_STATE] = state

  if (state.theme && (state.theme.heading_font || state.theme.body_font)) {
    initEmbeddedGoogleFont()
  }

  pwaTheme(state.theme)

  if (state.external) addExternalLinks(state.external.links)
  if (state.theme.global_css) addGlobalCSS(state.theme.global_css)
  if (state.theme.global_head_code)
    addGlobalCode(state.theme.global_head_code, true)
  if (state.theme.global_body_code)
    addGlobalCode(state.theme.global_body_code, false)

  if (state.app?.rewardful_id) {
    setupRewardful(state.app.rewardful_id)
  }

  // Should make available everywhere
  // https://github.com/stripe/stripe-js#ensuring-stripejs-is-available-everywhere
  if (state.payments.stripe_account_id) {
    setupStripe(state.payments.stripe_account_id)
  }

  if (state.app?.language) {
    i18n.locale = state.app?.language
  }
}

initializeApp()

fetchGlobalState().then((state: AppGlobalState) => {
  initializeApp(state)
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app")
