var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"text-block",class:[
    {
      '--align-center': _vm.align === 'center',
      '--align-right': _vm.align === 'right',
      '--invert-colors': _vm.invertColors,
      '--checked-authentication': _vm.hasCheckedAuthentication
    },
    _vm.colorClass,
    'text-block container'
  ],style:({ padding: _vm.blockPadding })},[_c('div',{class:[
      { '--collapsed': _vm.isCollapsed },
      'text-block__collapse-wrapper p-relative'
    ]},[_c('div',{staticClass:"text-block__collapse-content"},[[_c('div',{ref:"collapse-content",class:['text-block__content', { '--collapsed': _vm.isCollapsed }],attrs:{"data-role":"text-block-content"}},[_c('v-html',{ref:"html-content",attrs:{"data-role":"html","html":_vm.blockContent,"no-padding":""}})],1),(_vm.isCollapsable && !_vm.collapsed)?_c('v-button',{staticClass:"pl-0 ml-0 text-block__collapse-button",attrs:{"small":"","secondary":"","ghost":""},on:{"click":function () { return (_vm.collapsed = true); }}},[_c('h6',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$t("components.textBlock.viewLess"))+" ")])]):_vm._e()]],2),(_vm.isCollapsable && _vm.collapsed)?_c('div',{staticClass:"text-block__collapse-overflow p-overlay justify-content-start",on:{"click":function () { return (_vm.collapsed = false); }}},[_c('v-button',{staticClass:"pl-0 ml-0 text-block__collapse-button",attrs:{"small":"","secondary":"","ghost":""}},[_c('h6',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$t("components.textBlock.viewMore"))+" ")])])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }